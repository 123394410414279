import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import { FleetScheduler } from '@/model/FleetScheduler'
import { Rates } from '@/model/Rates'
import { Move, ServiceMove } from '@/model/Move'
import { Car } from '@/model/Car'

Vue.config.productionTip = false
Vue.use(Vuex)

const saveCars = (cars) => {
  const carModels = cars.map(x => (
    {
      id: x.id,
      segment: x.segment,
      location: x.location ?? 'Warszawa'
    }));
  const stringified = JSON.stringify(carModels);
  localStorage.setItem('cars', stringified);  
}

const saveServiceMoves = (serviceMoves) => {
  const serviceMovesModels = serviceMoves.map(m => ({
    segment: m.segment,
    start:m.start,
    end:m.end,
    locationStart: m.locationStart,
    locationEnd: m.locationEnd,
    carIndex: m.carIndex
  }));
  const stringified = JSON.stringify(serviceMovesModels);
  localStorage.setItem('serviceMoves', stringified);
}

const saveMoves = (moves) => {
  const movesModels = moves.map(m => ({
    segment: m.segment,
    start:m.start,
    end:m.end,
    locationStart: m.locationStart,
    locationEnd: m.locationEnd,
  }));
  const stringified = JSON.stringify(movesModels);
  localStorage.setItem('moves', stringified);
}

const store = new Vuex.Store({
  state: {
    start: 0,
    end: 20,
    lastCarId: 1,
    lastMoveId: 1,
    moves: [],
    serviceMoves: [],
    cars: [],
    rates: new Rates()
  },
  getters: {
    end: (state) => {
      return state.end;
    },
    start: (state) => {
      return state.start;
    },
    moves: (state) => {
      return state.moves;
    },
    serviceMoves: (state) => {
      return state.serviceMoves;
    }
  },
  actions: {
    initializeStore(context) {
      const cars = JSON.parse(localStorage.getItem('cars')) ?? [];
      for (let car of cars) {
        context.commit('addCar', car);
      }
      
      const moves = JSON.parse(localStorage.getItem('moves')) ?? [];
      context.commit('addMoves', moves);
      
      const serviceMoves = JSON.parse(localStorage.getItem('serviceMoves')) ?? [];
      context.commit('addServiceMoves', serviceMoves);
    },
  },
  mutations: {
    
    addCar(state, carModel) {
      if (carModel.id) {
        if (state.lastCarId < carModel.id) {
          state.lastCarId = carModel.id;
        } 
      } else {
        carModel.id = state.lastCarId++;
      }
      
      const newCar = new Car(parseInt(carModel.id), state.start, state.end, 
        carModel.segment, state.rates, carModel.location);
      state.cars.push(newCar);
      saveCars(state.cars);
    },
    
    deleteCar(state, car) {
      car.reset();
      state.cars = state.cars.filter(x => x.id !== car.id);
      saveCars(state.cars);
    },
    
    clear(state) {
      state.lastCarId = 1;
      state.cars = [];
      saveCars(state.cars);

      state.lastMoveId = 1;
      state.moves = [];
      saveMoves(state.moves);
      state.serviceMoves = [];
      saveServiceMoves(state.serviceMoves);
     
    },
    
    addServiceMoves(state, serviceMoves) {
      const newServiceMoves = [];
      for (let m of serviceMoves ?? []) {
        const car = state.cars.filter(c => c.id === parseInt(m.carIndex))[0];
        const move = new ServiceMove(state.lastMoveId++, car.id, m.start, m.end, car.segment, state.rates, 
          m.locationStart, m.locationEnd);
        newServiceMoves.push(move);
      }
      state.serviceMoves.push(...newServiceMoves);
      saveServiceMoves(state.serviceMoves);
    },

    addMoves(state, moves) {
      const newMoves = [];
      for (let m of moves) {
        const move = new Move(state.lastMoveId++, m.start, m.end, m.segment, state.rates, 
              m.locationStart, m.locationEnd);
        newMoves.push(move);
      }
      state.moves.push(...newMoves)
      saveMoves(state.moves);
    },
    
    deleteMove(state, move) {
      state.moves = state.moves.filter(x => x.id !== move.id);
      saveMoves(state.moves);
      
      if (move.owned) 
        move.owned.schedule.delete(move)
    },

    deleteServiceMove(state, move) {
      state.serviceMoves = state.serviceMoves.filter(x => x.id !== move.id);
      saveServiceMoves(state.serviceMoves);
      
      if (move.owned) 
        move.owned.schedule.delete(move)
    },

    optimize(state) {
      const fs = new FleetScheduler(state.start, state.end, state.cars);
      if (state.moves.length > 0) {
        console.time('scheduling');
        fs.schedule(state.serviceMoves.concat(state.moves));
        console.timeEnd('scheduling');  
      }
    },
  }
});

new Vue({
  store,
  beforeCreate() { this.$store.dispatch('initializeStore'); },
  render: h => h(App)
}).$mount('#app');

