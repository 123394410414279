<template>
    <div class="schedule" 
        :style="{
            'border-style':'solid', 
            'border-width':'thin',
            }">
        <ScheduleCarItem 
            v-for="car in cars"
            :key="car.id"
            :car="car"
        ></ScheduleCarItem>
    </div>
</template>

<script>
import ScheduleCarItem from '@/components/ScheduleCarItem.vue'

export default {
    name: "ScheduleItem",
    components: {
        ScheduleCarItem
    },
    props: {
        cars: {
            type: Array,
            required: true
        }
    },
}

</script>

<style scoped>
.schedule {
    padding: 4px 4px;
    
}
</style>
