<template>
    <div id="app">
        <button v-on:click="$store.commit('optimize')">Schedule</button>
        <button v-on:click="$store.commit('clear')">Clear</button>
        <hr/>
        <div class="control">
        <MovesItem/>
        <ServiceMovesItem/>
        <FleetItem 
          :cars="cars" 
          v-on:car-added="onCarAdded"
          v-on:car-deleted="onCarDeleted"
        >
        </FleetItem>
        </div>
        <hr/>
        <ScheduleItem 
          :cars="cars" 
          :from="getStart()" 
          :to="getEnd()">
        </ScheduleItem>
    </div>
</template>
<script>
import ScheduleItem from '@/components/ScheduleItem.vue'
import MovesItem from '@/components/MovesItem.vue'
import ServiceMovesItem from '@/components/ServiceMovesItem.vue'
import FleetItem from '@/components/FleetItem.vue'

export default {
  name: 'App',
  components: {
    ScheduleItem,
    MovesItem,
    ServiceMovesItem,
    FleetItem
  },
  computed: {
    cars () {
      return this.$store.state.cars;
    }
  },
  methods: {
    onCarAdded: function(carModel) {
      this.$store.commit('addCar', carModel)
    },
    onCarDeleted: function(car) {
      this.$store.commit('deleteCar', car)
    },
    getStart: function() {
      return this.$store.getters.start;
    },
    getEnd: function() {
      return this.$store.getters.end;
    }
  },
}
</script>
<style scoped>
.control {
  display: flex;
  flex-wrap: wrap;
}
</style>