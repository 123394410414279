class Move {
    /**
     * 
     * @param {Number} id 
     * @param {Number} start 
     * @param {Number} end 
     * @param {String} segment 
     * @param {Rates} rates 
     */
    constructor(id, start, end, segment, rates, locationStart, locationEnd) {
        this.id = id;

        this.start = parseInt(start);
        this.end = parseInt(end);

        this.locationStart = locationStart;
        this.locationEnd = locationEnd;
        if (!locationStart || ! locationEnd) throw new Error('Move without location');

        this.segment = segment; 
        if (!segment) throw new Error('Move without segment!');
        
        this.rates = rates; 
        if (!rates) throw new Error('Move without rates!');

        this.next = null;
        this.owned = null;
    }
    
    profit() {
        let carSegment = this.segment;
        if (this.owned) 
            carSegment = this.owned.segment;

        const moveLen = this.length();
        const profitUnit = this.rates.moveSegmentProfit[this.segment + carSegment];
        
        return profitUnit * moveLen;
    }

    cost() {
        return 0; // base move has no cost
    }

    free() {
        this.owned = null;
        this.next = null;
    }
    
    own(car) {
        this.owned = car;
    }

    length() {
        return this.end - this.start;
    }
    
    requiredDistanceTo (move) {
        if (move === null) return 0;
        return this.rates.requiredTimes[this.locationEnd + '-' + move.locationStart];
    }

    distanceTo (move) {
        if (move === null) return 0;
        return move.start - this.end;
    }

    distanceToNext() {
        return this.distanceTo(this.next);
    }

    endsBefore (move) {
        if (move === null) return true;
        return this.end <= move.start;
    }
    
    overlaps (move) {
        return move.start < this.end &&
            this.start < move.end;
    }

    getTail () {
        let x = this;
        while (x.next !== null) {
            x = x.next;
        }
        return x;
    }
    
    append(move) {
        const movesTail = move.getTail();

        if (this.overlaps(movesTail)) {
            throw new Error('cannot append overlapping move');
        }

        if (this.next !== null) {
            if (this.next.overlaps(movesTail)) {
                throw new Error('cannot append overlapping move');
            }
            movesTail.append(this.next);
        }

        this.next = move;
    }

    requirementsAreMetBy(car) {
        console.debug('requirement check for car %d', car.id);
        return true;
    }
}

class StartMove extends Move {
    /**
     * 
     * @param {Number} start 
     * @param {Number} end 
     * @param {String} segment 
     * @param {Rates} rates 
     */
     constructor(start, end, segment, rates, carLocation) {
        super(null, start, end, segment, rates, carLocation, carLocation);
    }
    
    profit() {
        return 0;
    }

    append(move) {
        super.append(move);
        this.end = move.start;
    }
    
    endsBefore(move) {
        if (move === null) return true;
        return this.start <= move.start;
    }
      
    distanceTo(move) {
        if (move === null) return 0;
        return move.start - this.start;
    }

    overlaps() {
        return false;
    }
}

class RelocationMove extends Move {
    /**
     * 
     * @param {Move} move 
     * @param {String} relocationStartPlace 
     * @param {Number} relocationTimeDistance 
     * @param {String} carSegment 
     */
    constructor(move, relocationStartPlace, relocationTimeDistance, carSegment) {
        super('-' + move.id, move.start - relocationTimeDistance, 
            move.start, carSegment, move.rates, relocationStartPlace, move.locationStart);

        /** @type {Move} */
        this.relocatedFor = move;
    }

    profit() {
        return 0;
    }

    /**
     * Fuel cost of relocation estimated based on the time required to arrive to destination.
     * @returns cost of fuel
     */
    cost() {
        const cost = this.length() * this.relocatedFor.rates.fuelCostPerLocationDistanceUnit;
        return cost;
    }
}

class ServiceMove extends Move {
    constructor(moveIndex, carIndex, start, end, segment, rates, locationStart, locationEnd) {
        super(moveIndex, start, end, segment, rates, locationStart, locationEnd);
        
        this.carIndex = parseInt(carIndex);
    }

    profit() {
        return 0;
    }
    
    cost() {
        const cost = this.length() * this.rates.fuelCostPerLocationDistanceUnit;
        return cost;
    }

    requirementsAreMetBy(car) {
        return this.carIndex === car.id;
    }
}

module.exports = {
    Move, StartMove, RelocationMove, ServiceMove
}