<template>
    <div>
        <table>
            <thead>
            <tr>
                <th>Id</th>
                <th>Loc Start</th>
                <th>Loc End</th>
                <th>Start</th>
                <th>End</th>
                <th>Car</th>                
                <th>Action</th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="move in serviceMoves"
                    :move="move"
                    :key="move.id"
                    :class="getClassess(move)"
                >
                    <td>{{move.id}}</td>
                    <td>{{move.locationStart}}</td>
                    <td>{{move.locationEnd}}</td>
                    <td>{{move.start}}</td>
                    <td>{{move.end}}</td>
                    <td>{{move.carIndex}}</td>
                    <td><button v-on:click.prevent="deleteMove(move)">Delete</button></td>
                </tr>  
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <p>New:</p>
                    </td>
                    <td>
                        <select v-model="newMoveLocationStart">
                            <option disabled value="">+</option>
                            <option>Warszawa</option>
                            <option>Gdańsk</option>
                            <option>Kraków</option>
                        </select>
                    </td>
                    <td>
                        <select v-model="newMoveLocationEnd">
                            <option disabled value="">+</option>
                            <option>Warszawa</option>
                            <option>Gdańsk</option>
                            <option>Kraków</option>
                        </select>
                    </td>
                    <td>
                        <input v-model="newMoveStart" id="new-move-start">
                    </td>
                    <td>
                        <input v-model="newMoveEnd" id="new-move-end">
                    </td>
                    <td>
                        <input v-model="newMoveCarId" id="new-move-car-id">
                    </td>
                    <td>
                        <button v-on:click.prevent="addServiceMove">Add</button>
                    </td>
                </tr>
            </tfoot>  
        </table>
    </div>
</template>

<script>
export default {
    name: "ServiceMovesItem",
    computed: {
        serviceMoves: function () {
            return this.$store.getters.serviceMoves;
        }
    },
    methods: {
        getClassess(move) {
            return [
                isNaN(move.carIndex) ? '' : 'service',
                move.owned ? 'owned' : 'free'
            ]
        },
 
        profitFormatted(move) {
            return new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' })
                .format(move.profit());
        },
        costFormatted(move) {
            return new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' })
                .format(move.cost());
        },
        addServiceMove() {
            this.$store.commit('addServiceMoves', 
                [
                    {
                        start: this.newMoveStart, 
                        end: this.newMoveEnd, 
                        locationStart: this.newMoveLocationStart,
                        locationEnd: this.newMoveLocationEnd,
                        carIndex: this.newMoveCarId
                    }
                ]);
            
            this.newMoveStart = null;
            this.newMoveEnd = null;
            this.newMoveLocationStart = null;
            this.newMoveLocationEnd = null;
            this.newMoveCarId = null;
        },
        deleteMove(move) {
            this.$store.commit('deleteServiceMove', move);
        },
    },
    data: function() {
        return {
            newMoveStart: null,
            newMoveEnd: null,
            newMoveLocationStart: null,
            newMoveLocationEnd: null,
            newMoveCarId: null
        }
    }
}
</script>
<style scoped>
.move  {
    padding-right: 1em;
}
table {
  border: 2px lightblue;
  opacity: 1;
  border-style: ridge;
  
  text-align: center;
  border-collapse: collapse;
}
table td {
  padding: 0.4em ;
  border: 2px lightblue;
  border-style: ridge;
  border-width: thin;
}
table input {
    width: 5em;
}
.service {
    background-color: lightgray;
}

.owned {
    background-color: lightseagreen;
}
.free {
    background-color: navajowhite;
}
</style>