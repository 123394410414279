const { RelocationMove } = require('./Move.js');
const { CarSchedule } = require("./CarSchedule.js");

class Car {
    
    /**
     * 
     * @param {Number} id 
     * @param {Number} start 
     * @param {Number} end 
     * @param {String} segment 
     * @param {Rates} rates 
     */
    constructor(id, start, end, segment, rates, location) {
        this.id = id;
        this.start = start;
        this.end = end;
        this.location = location; if (!location) throw new Error('Car without location');
        this.segment = segment; if (!segment) throw new Error('Car without segment');
        this.rates = rates;
        this.schedule = new CarSchedule(start, end, this.segment, this.rates, this.location);
        
        /** @type {Move} */
        this.consideredMove = null;
        /** @type {RelocationMove} */
        this.consideredRelocation = null;
        /** @type {Move} */
        this.consideredPlace = null;
    }
       
    profit() {
        let profitSum = 0;
        if (this.consideredMove !== null) {
            profitSum = this.consideredMove.length() * this.rates.moveSegmentProfit[this.segment + this.consideredMove.segment];
        }
            
        this.schedule.forEach(move => {
            profitSum += move.length() * this.rates.moveSegmentProfit[move.segment + this.segment];
        });

        return profitSum;
    }

    cost() {
        const length = this.schedule.length();
        const segmentCost = this.rates.carSegmentCosts[this.segment];

        let costSum = length * segmentCost;

        if (this.consideredRelocation !== null) {
            costSum += this.consideredRelocation.cost();
        }

        this.schedule.forEach(move => {
            costSum += move.cost();
        });
        
        return costSum;
    }

    reset() {
        this.consideredMove = null;
        this.consideredPlace = null;
        this.consideredRelocation = null;
        this.schedule.releaseMoves();
    }
    
    consider(move) {
        console.debug('car: %d | move: %d | consider', this.id, move.id);
        if (this.consideredMove !== null) {
            throw new Error('Car has already move under consideration. Please accept or decline first');
        }
        
        this.consideredMove = move;

        if (this.segment < move.segment) {
            return -1;
        }

        if (! move.requirementsAreMetBy(this)) {
            return -1;
        }

        const place = this.schedule.findPlaceFor(move);
        this.consideredPlace = place;
        if (place === null) {
            return -1;
        }
        
        const placeToMoveTimeAvailable = place.distanceTo(move);
        const placeToMoveTimeRequired = place.requiredDistanceTo(move);
        const distanceDifference = placeToMoveTimeAvailable - placeToMoveTimeRequired;
        if (distanceDifference < 0) {
            return -1;
        }
        
        if (placeToMoveTimeRequired > 0) {
            this.consideredRelocation = new RelocationMove(move, place.locationEnd, 
                placeToMoveTimeRequired, this.segment);
        }

        const load = this.load();
        const profitability = this.profitability();

        const s = load * profitability;
        
        console.debug('car: %d | move: %d | profitability: %f | load: %f', 
            this.id, this.consideredMove.id, profitability, load);

        return s;
    }
    
    consideredCost() {
        const consideredMoveCost = this.consideredMove.length() * this.rates.carSegmentCosts[this.segment];
        const consideredRelocationCost = this.consideredRelocation !== null ? this.consideredRelocation.cost() : 0;
        return consideredMoveCost + consideredRelocationCost;
    }  

    profitability() {
        if (this.consideredMove === null) return 0;

        const min = this.consideredCost() * -1;
        const max = this.consideredMove.profit();
        const profit = this.consideredMove.profit();
        const cost = this.consideredCost() * -1;
        const result =  profit + cost;
        
        const resultScore = (result - min) / (max - min);

        return resultScore;
    }

    load() {
        const movesLength = this.schedule.movesLength() + this.consideredMove.length();
        const period = this.end - this.start;
        const load = movesLength / period;
        return load;
    }

    accept() {
        if (this.consideredMove === null) {
            throw new Error('Cannot accept. No move under consideration. Please Consider a move first');    
        }
        
        console.debug('car: %d | move: %d | accept', this.id, this.consideredMove.id);

        if (this.consideredRelocation !== null) {
            this.schedule.appendTo(this.consideredPlace, this.consideredRelocation);
            this.schedule.appendTo(this.consideredRelocation, this.consideredMove);
            this.consideredRelocation.own(this);
            this.consideredRelocation = null;
        } else {
            this.schedule.appendTo(this.consideredPlace, this.consideredMove);
        }

        this.consideredMove.own(this);

        this.consideredMove = null;
        this.consideredPlace = null;
    }

    decline() {       
        if (this.consideredMove === null) {
            throw new Error('Cannot decline. No move under consideration. Please Consider a move first');
        }
        
        console.debug('car: %d | move: %d | decline', this.id, this.consideredMove.id);

        if (this.consideredRelocation !== null) {
            this.consideredRelocation.free();
        }        

        this.consideredRelocation = null;
        this.consideredPlace = null;
        this.consideredMove = null;
    }
}

module.exports = {
    Car
}