const { StartMove } = require('./Move.js');

class CarSchedule {
    /**
     * 
     * @param {Number} start 
     * @param {Number} end 
     * @param {String} segment 
     * @param {Rates} rates 
     */
    constructor(start, end, segment, rates, carLocation) {
        this.start = start;
        this.end = end;
        this.segment = segment;
        this.rates = rates;
        this.carLocation = carLocation;
        this.head = new StartMove(start, start, segment, rates, carLocation);
    }
    
    length() {
        return this.end - this.start;
    }

    releaseMoves() {
        let head = this.head;
        let tmp = head;
        while (head !== null) {
            tmp = head;
            head = head.next;
            tmp.next = null;
            tmp.free();
        }
        this.head = new StartMove(this.start, this.start, this.segment, this.rates, this.carLocation);
    }

    forEach(elemAction) {
        let head = this.head.next;
        while (head !== null) {
            elemAction(head);
            head = head.next
        }
    }

    /**
     * 
     * @returns {Number}
     */
    movesLength() {
        let length = 0;
        this.forEach(x => length += x.length());
        return length;
    }
    
    /**
     * returns move which is previous for
     * the one provided in parameter.
     * @param {Move} move 
     * @returns {Move} 
     */
    findPlaceFor(move) {
        if (move.start > this.end || move.end < this.start) {
            return null;
        }

        let head = this.head;

        while (head !== null) {
            if (head.endsBefore(move) && move.endsBefore(head.next)) {
                return head;
            } else {
                head = head.next;
            }
        }

        return null;
    }

    /**
     * 
     * @param {Move} target 
     * @param {Move} move 
     */
    appendTo(target, move) {
        if (! this.contains(target)) throw new Error('target is not in this schedule. cannot append move');
        target.append(move);
    }

    /**
     * 
     * @param {Move} move 
     */
    delete(move) {
        let prev = this.head;
        let head = this.head;
        while (head !== null && prev.next !== move) {
            prev = head;
            head = head.next
        }

        prev.next = move.next;
        move.free();
    }
    
    /**
     * 
     * @param {Move} move 
     * @returns {Boolean}
     */
    contains(move) {
        let head = this.head;
        while (head !== null) {
            if (head === move) return true;
            head = head.next;
        }
        return false;
    }
    /**
     * 
     * @returns {Move}
     */
    getTail() {
        let head = this.head;
        while (head !== null) {
            if (head.next === null) return head;
            head = head.next;
        }
        return head;
    }
}

module.exports = {
    CarSchedule
}