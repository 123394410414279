/**
 * distributes moves in a way that the cars 
 * are utilized optimally
 */
 class FleetScheduler {
    /**
     * 
     * @param {Number} from 
     * @param {Number} to 
     * @param {Array.<Car>} cars
     */
    constructor(from, to, cars) {
        this.from = from;
        this.to = to;
        this.cars = cars;
    }
    
    /**
     * 
     * @param {Move} a 
     * @param {Move} b 
     * @returns 
     */
    moveSorter(a, b) {
        if (a.time === b.time) {
            if (a.segment > b.segment) return -1;
            if (a.segment < b.segment) return 1;

            if (a.type === 'start' && b.type === 'end') return -1;
            if (a.type === 'end' && b.type === 'start') return 1;

            return b.length - a.length;
        }
        return a.time - b.time;
    }

    schedule(moves) {
        this.cars.forEach(car => {
           car.reset();
        });

        const events = [];
        moves.forEach(m => events.push(
            {
                'ref': m,
                'segment': m.segment, 
                'time': m.start, 
                'type': 'start',
                'length': m.length()
            }, 
            {
                'ref': m,
                'segment': m.segment,
                'time': m.end, 
                'type':'end',
                'length': m.length()
            }));
    
        events.sort(this.moveSorter);

        // let cars_needed = 0;
        // let minCarsCount = 1;
        // events.forEach(event => {
        //     if (event.type === 'start') {
        //         cars_needed ++;
        //     } else {
        //         cars_needed --;
        //     }
        //     if (cars_needed > minCarsCount) {
        //         minCarsCount = cars_needed;
        //     }
        // });
        
        events.filter(event => event.type === 'start')
            .forEach(event => {
                const move = event.ref;
                const cars = this.cars.map(car => ({
                    car: car,
                    score: car.consider(move)
                }));

                const disqualifiedCars = cars.filter(car => car.score < 0);
                
                disqualifiedCars.forEach(car => car.car.decline());
                
                const qualifiedCars = cars.filter(car => car.score >= 0);
                
                qualifiedCars.sort((a,b) => {
                    return a.score - b.score;
                });
                
                const bestCar = qualifiedCars.pop();
                
                qualifiedCars.forEach(car => {
                    car.car.decline();
                });
                if (bestCar)
                    bestCar.car.accept();
            });
    }    
}

module.exports = {
    FleetScheduler
}