class Rates {
    /**
     * 
     * @param {Object.<string, number>} carSegmentCosts 
     * @param {Object.<string, number>} moveSegmentProfit 
     */
    constructor(carSegmentCosts, moveSegmentProfit) {
        this.fuelCostPerLocationDistanceUnit = 1;

        this.requiredTimes = {
            'Warszawa-Warszawa':0,
            'Warszawa-Kraków':1,
            'Warszawa-Gdańsk':1,

            'Kraków-Kraków':0,
            'Kraków-Warszawa':1,
            'Kraków-Gdańsk':2,

            'Gdańsk-Gdańsk':0,
            'Gdańsk-Warszawa':1,
            'Gdańsk-Kraków':2
        }

        this.carSegmentCosts = carSegmentCosts ?? {
            'a':1,
            'b':2,
            'c':3
        }
        
        this.moveSegmentProfit = moveSegmentProfit ?? {
            'aa':1.5,
            'bb':3.0,
            'cc':4.5,

            'ab': 0.5,
            'bc': 0.3,
            'ac': 0.1,
            
            // cannot assign lower class car to higher move class
            'ba': null,
            'cb': null,
            'ca': null
        }
    }

    
}

module.exports = {
    Rates
}