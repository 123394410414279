<template>
    <div :class="['car', car.segment]">
        <div
            :class="['carInfo']"
        >
            <span>{{car.id}}</span>
        </div>
        <div 
            v-for="move in moves" 
            v-on:click="select($event, move)"
            :class="move.getClasses()"
            :key="move.id" 
            :style="{ flex: move.length() }"
        >
            <div 
                :class="['moveInfo']"
            >
                <span>{{move.id}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { Car } from '@/model/Car'

class MoveViewModel {
    /**
     * 
     * @param {Number} start 
     * @param {Number} end 
     * @param {String} segment 
     * @param {string} location 
     */
    constructor(id, start, end, segment, locationStart, locationEnd, carIndex) {
        this.start = start;
        this.end = end;
        this.segment = segment;
        this.locationStart = locationStart;
        this.locationEnd = locationEnd;
        this.id = id;
        this.carIndex = carIndex;
    }
    
    getClasses() {
        return [
            isNaN(this.carIndex) ? this.id !== null ? this.segment : '' : 'service',
            
            this.id === null ? 'empty' : 'move', 
        ]
    }

    length() {
        return this.end - this.start;
    }
}

const getMovesList = (car) => {
    const result = [];
    let prevEnd = car.start;
    let head = car.schedule.head.next; // fist node is just a list anchor
    while (head !== null) {
        const gap = head.start - prevEnd;
        if (gap > 0) {
            result.push(new MoveViewModel(null, prevEnd, head.start, car.segment, car.location));
        }
        result.push(new MoveViewModel(head.id, head.start, head.end, head.segment, head.locationStart, head.locationEnd, head.carIndex));
        prevEnd = head.end;
        head = head.next;
    }
    
    const tail = car.end - prevEnd;
    if (tail > 0) {
        result.push(new MoveViewModel(null, prevEnd, car.end, car.segment, car.location));
    }
    
    return result;
}

export default {
    name: "ScheduleCarItem",
    props: {
        car: Car,
    },
    computed: {
        moves: function() {
            return getMovesList(this.car);
        }
    },
    methods: {
        select: function($event, id) {
            console.log($event, id);
        },
    }
}

</script>
<style scoped>

.car {
    height: 3em;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border-style: ridge;
    border-width: thin;
}

.carInfo {
    display: flex;
    flex: 1 1 0%;
    justify-content: center;
    align-items: center;
}

.move {
    box-shadow: 0 0 0 0.2em white inset;
    outline:0.1em solid black;    
}

.moveInfo {
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.moveInfo span {
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.service {
    background-color: lightgray;
}

.a.move {
    background-color: lightgreen;
}

.b.move {
    background-color: lightsalmon;
}

.c.move {
    background-color: lightskyblue;
}

.empty {    
    box-shadow: 0px 0px;
    opacity: 0;
}

.car.a {
    background-color: #ffffff;
    opacity: 1;
    background-image:  repeating-linear-gradient(45deg, #90ee90 25%, transparent 25%, transparent 75%, #90ee90 75%, #90ee90), repeating-linear-gradient(45deg, #90ee90 25%, #ffffff 25%, #ffffff 75%, #90ee90 75%, #90ee90);
    background-position: 0 0, 12px 12px;
    background-size: 24px 24px;
}

.car.b {
    background-color: #ffffff;
    opacity: 1;
    background-image:  repeating-linear-gradient(45deg, #ffa07a 25%, transparent 25%, transparent 75%, #ffa07a 75%, #ffa07a), repeating-linear-gradient(45deg, #ffa07a 25%, #ffffff 25%, #ffffff 75%, #ffa07a 75%, #ffa07a);
    background-position: 0 0, 12px 12px;
    background-size: 24px 24px;
}

.car.c {
    background-color: #ffffff;
    opacity: 1;
    background-image:  repeating-linear-gradient(45deg, lightskyblue 25%, transparent 25%, transparent 75%, lightskyblue 75%, lightskyblue), repeating-linear-gradient(45deg, lightskyblue 25%, #ffffff 25%, #ffffff 75%, lightskyblue 75%, lightskyblue);
    background-position: 0 0, 12px 12px;
    background-size: 24px 24px;
}

</style>