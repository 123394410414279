<template>
    <div>
        <table>
            <thead>
                <th>Id</th>
                <th>Loc Start</th>
                <th>Segment</th>
                <th>Profit</th>
                <th>Cost</th>
                <th>Action</th>
            </thead>
            <tbody>
                <tr v-for="car in cars"
                    :key="car.id"
                    :car="car"
                >
                    <td>{{car.id}}</td>
                    <td>{{car.location}}</td>
                    <td>{{car.segment}}</td>
                    <td>{{profitFormatted(car)}}</td>
                    <td>{{costFormatted(car)}}</td>
                    <td><button v-on:click.prevent="deleteCar(car)">Delete</button></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>New</td>
                    <td>
                        <select v-model="newLocation">
                            <option disabled value="">Please select one</option>
                            <option>Warszawa</option>
                            <option>Gdańsk</option>
                            <option>Kraków</option>
                        </select>
                    </td>
                    <td>
                        <select v-model="newSegment">
                            <option disabled value="">Please select one</option>
                            <option>a</option>
                            <option>b</option>
                            <option>c</option>
                        </select>
                    </td>
                    <td/>
                    <td/>
                    <td><button v-on:click.prevent="addCar">Add</button></td>
                </tr>
            </tfoot>
        </table>
    </div>    
</template>
<script>
export default {
    name: "FleetItem",
    components: {
        
    },
    props: {
        cars: {
            type: Array,
            required: true
        }        
    },
    data: function() {
        return {
            newSegment: null,
            newLocation: null,
        }
    },
    methods: {
        profitFormatted(car) {
            return new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' })
                .format(car.profit());
        },
        costFormatted(car) {
            return new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' })
                .format(car.cost());
        },
        addCar: function() {
            this.$emit('car-added', 
            {
                segment: this.newSegment,
                location: this.newLocation
            });
            this.newSegment = null;
        },
        deleteCar: function(car) {
            this.$emit('car-deleted', car);
        }
    }
}
</script>
<style scoped>
table {
  border: 2px lightblue;
  opacity: 1;
  border-style: ridge;
  
  text-align: center;
  border-collapse: collapse;
}
table td {
  padding: 0.4em ;
  border: 2px lightblue;
  border-style: ridge;
  border-width: thin;
}
table input {
    width: 5em;
}
</style>